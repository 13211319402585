import { TalentAvailability } from '@upper/graphql/freelancer'
import { classNames } from '@upper/ui'
import { formatDate } from '@upper/utils'
import {
  SidebarCollapsableButton,
  SidebarCollapsableButtonProps,
} from './sidebar-collapsable-button'

export const AVAILABILITY_LABELS: Record<TalentAvailability, string> = {
  FullTime: 'Full-Time',
  PartTime: 'Part-Time',
  Unavailable: 'Unavailable',
}

const AVAILABILITY_COLORS: Record<TalentAvailability, string> = {
  FullTime: 'bg-green',
  PartTime: 'bg-green',
  Unavailable: 'bg-red',
}

export function SidebarAvailabilityIcon({
  availability,
}: {
  availability: TalentAvailability | null | undefined
}) {
  return (
    <div className="relative z-0">
      <svg
        width={23}
        height={21}
        viewBox="0 0 23 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-shrink-0"
      >
        <path
          d="M12.5 19.782H2.043A1.043 1.043 0 011 18.738V4.13a1.043 1.043 0 011.043-1.043h18.783a1.043 1.043 0 011.044 1.043V11.5M6.217 1v5.217M16.652 1v5.217M1 8.304h20.87"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div
        className={classNames(
          'absolute -bottom-1 -right-1 h-3 w-3 rounded-full',
          availability && AVAILABILITY_COLORS[availability]
        )}
      />
    </div>
  )
}

export function SidebarAvailabilityButton({
  availability,
  nextAvailability,
  ...props
}: {
  availability: TalentAvailability | null | undefined
  nextAvailability?: string
} & Partial<SidebarCollapsableButtonProps>) {
  const tooltipContent: Record<TalentAvailability, string> = {
    FullTime: `Available full-time from ${
      nextAvailability ? formatDate(nextAvailability) : ''
    }`,
    PartTime: `Available part-time from ${
      nextAvailability ? formatDate(nextAvailability) : ''
    }`,
    Unavailable: 'Unavailable.',
  }

  return (
    <SidebarCollapsableButton
      {...props}
      icon={<SidebarAvailabilityIcon availability={availability} />}
      label={availability ? AVAILABILITY_LABELS[availability!] : 'Not set'}
      tooltip={availability ? tooltipContent[availability!] : null}
      canEdit
    />
  )
}
